import React, { useState, useEffect } from "react"
import styled from "styled-components"

// usage
// <CheckRankingAnswer
//    data={{
//      orderData: replyMode
//        ? item.user_input.split('')
//        : item.user_input_ordered_selections,
//    }}
// />

// elements
const CheckRankingDiv = styled.div`
  display: flex;
  flex-direction: row;
`
const CheckRankingInnerDiv = styled.div<{
  position: string
}>`
  margin-left: ${(props) => (props.position == "right" ? "10rem" : "")};
`
const OrderItemDiv = styled.div`
  display: flex;
  margin-bottom: 1rem;
`
const OrderItemTextSpan = styled.span`
  color: #f2ab27;
  margin-right: 1rem;
`
const OrderItemP = styled.p`
  margin: 0;
`

// type
export type CheckRankingPropType = {
  data: { orderData: string[] }
}

export const CheckRankingAnswer = ({ data: { orderData } }: CheckRankingPropType) => {
  const [leftArray, setLeftArray] = useState<string[] | null>(null)
  const [rightArray, setRightArray] = useState<string[] | null>(null)

  useEffect(() => {
    if (orderData.length > 13) {
      setLeftArray(orderData.slice(0, 13))
      setRightArray(orderData.slice(13, orderData.length))
    } else {
      setLeftArray(orderData.slice(0, orderData.length))
    }
  }, [orderData])

  const OrderItem = ({ orderItem, orderIndex }: { orderItem: string; orderIndex: number }) => {
    return (
      <OrderItemDiv>
        <OrderItemTextSpan>{orderIndex}순위</OrderItemTextSpan>
        <OrderItemP className="valueText">{orderItem}</OrderItemP>
      </OrderItemDiv>
    )
  }

  return (
    <CheckRankingDiv>
      <CheckRankingInnerDiv position="left">
        {leftArray ? leftArray.map((orderItem, orderIndex) => <OrderItem key={orderIndex} orderItem={orderItem} orderIndex={orderIndex + 1} />) : null}
      </CheckRankingInnerDiv>
      <CheckRankingInnerDiv position="right">
        {rightArray ? rightArray.map((orderItem, orderIndex) => <OrderItem key={orderIndex} orderItem={orderItem} orderIndex={orderIndex + 1 + 13} />) : null}
      </CheckRankingInnerDiv>
    </CheckRankingDiv>
  )
}
