import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Individual from "./pages/individual/page"

function App() {
  return (
    <div className="App">
      <Router>
        <Route exact path="/individual/:uid" component={Individual} />
      </Router>
    </div>
  )
}

export default App

