import React, { useEffect, useReducer } from "react"
import styled from "styled-components"
import { Header, AnswerList } from "./components"

const IndividualContainerDiv = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`

// 리듀서 로직
export type ResponseItemType = {
  selections: string[]
  questionTitle: string
  questionIndex: number
  questionType: string
  answer: string
  isRequired: boolean
  image_selections: string[] | null
}

// 컴포넌트 props 타입
export type IndividualPropType = {
  surveyTitle: string | null
  surveyDescription: string | null
  surveyId: string | null
  responseData: ResponseItemType[]
}

// 컴포넌트 로직
function IndividualContainer({ surveyId, surveyTitle, surveyDescription, responseData }: IndividualPropType) {
  if (responseData) {
    return (
      <IndividualContainerDiv>
        <Header surveyId={surveyId!} surveyTitle={surveyTitle!} surveyDescription={surveyDescription!} />
        <AnswerList responseData={responseData} />
      </IndividualContainerDiv>
    )
  } else {
    return <h1>서버사이드 렌더링이 잘 된다면 이게 나오면 안됌</h1>
  }
}

export default IndividualContainer
