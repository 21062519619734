import React from "react"
import styled from "styled-components"

const HeaderBox = styled.div`
  background-color: white;
  border: 1px solid #e9e1d5;
  width: 100%;
  text-align: center;
  border-top: 0.6rem solid #fac609;
  border-radius: 5px;
`
const HeaderInnerBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 3rem;
  margin-top: 3rem;
  margin-right: 3rem;
  width: 100%;
  padding: 0 70px;
  align-items: center;
`
const HeaderLeftBox = styled.div`
  width: 200px;
`
const HeaderLeftBoxH1 = styled.h1`
  font-family: "Moto Heavy Demo";
  color: #fac609;
`
const HeaderRightBox = styled.div`
  text-align: left;
  width: 800px;
`
const HeaderRightBoxH1 = styled.h1`
  font-family: "Noto Sans CJK KR Regular";
  color: #1f1f1f;
  margin-top: 0;
  max-width: 55rem;
`
const HeaderRightBoxP = styled.div`
  font-family: "Noto Sans CJK KR Regular";
  line-height: 1.5rem;
  color: #818282;
  max-width: 55rem;
`

export type HeaderPropType = {
  surveyId: string
  surveyTitle: string
  surveyDescription: string
}

export function Header({ surveyId, surveyTitle, surveyDescription }: HeaderPropType) {
  return (
    <HeaderBox>
      <HeaderInnerBox>
        <HeaderLeftBox>
          <HeaderLeftBoxH1>{surveyId}</HeaderLeftBoxH1>
        </HeaderLeftBox>
        <HeaderRightBox>
          <HeaderRightBoxH1>{surveyTitle}</HeaderRightBoxH1>
          <HeaderRightBoxP>{surveyDescription}</HeaderRightBoxP>
        </HeaderRightBox>
      </HeaderInnerBox>
    </HeaderBox>
  )
}
