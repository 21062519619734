import React from "react"
import styled from "styled-components"

const RadioAnswerDiv = styled.div``
const RadioAnswerItem = styled.div`
  display: flex;
  align-items: center;
`
const RadioInput = styled.input`
  &[type="radio"] {
    width: 0.9rem;
    height: 0.9rem;
    border-radius: 100%;
    margin-right: 0.1rem;
    background-color: white;
  }
  &[type="radio"]:checked {
    appearance: none;
    width: 0.9rem;
    height: 0.9rem;
    border-radius: 100%;
    margin-right: 0.1rem;
    border: 3px solid orange;
  }
`
const RadioInputLabel = styled.label`
  margin-left: 10px;
`

export type RadioAnswerType = {
  data: {
    questionItem: string[]
    questionIndex: number
  }
  answer: string
}

export const RadioAnswer = ({ data: { questionItem }, answer }: RadioAnswerType) => {
  let isEtcExist = true
  if (answer && !questionItem.includes(answer)) isEtcExist = false

  return (
    <RadioAnswerDiv>
      {questionItem.map((answersItem, answersIndex) => {
        if (isEtcExist) {
          return (
            <RadioAnswerItem key={answersIndex}>
              <RadioInput data-testid="radio-answer" type="radio" checked={answersItem === answer} readOnly />
              <RadioInputLabel>{answersItem}</RadioInputLabel>
            </RadioAnswerItem>
          )
        } else {
          return (
            <RadioAnswerItem key={answersIndex}>
              <RadioInput data-testid="radio-answer" type="radio" checked={answersIndex === questionItem.length - 1} readOnly />
              <RadioInputLabel>{answersIndex === questionItem.length - 1 ? answersItem + answer : answersItem}</RadioInputLabel>
            </RadioAnswerItem>
          )
        }
      })}
    </RadioAnswerDiv>
  )
}
