import React from "react"
import styled from "styled-components"

import checkImg from "../../assets/check.svg"

// elements
const CheckAnswerList = styled.div``
const CheckItem = styled.div`
  display: flex;
  align-items: center;
`
const CheckBox = styled.input`
  &[type="checkbox"] {
    appearance: none;
    width: 1rem;
    height: 1rem;
    border: 0.5px solid rgba(0, 0, 0, 0.3);
  }
  &[type="checkbox"]:checked {
    background-color: #f2ab27;
    border: 2px solid #f2ab27;
    background-image: url(${checkImg});
    background-repeat: no-repeat;
  }
`
const CheckBoxLabel = styled.label`
  margin-left: 10px;
`

// type
export type CheckAnswerPropType = {
  data: {
    questionItem: string[]
    questionIndex: number
  }
  answer: string
  etc: string
}

export const CheckAnswer = ({ data: { questionItem, questionIndex }, answer, etc }: CheckAnswerPropType) => {
  return (
    <CheckAnswerList>
      {questionItem.map((answersItem, answersIndex) => (
        <CheckItem key={answersIndex}>
          <CheckBox data-testid="check-answer" type="checkbox" checked={answer.includes(answersItem)} readOnly />
          <CheckBoxLabel>
            {/* 기타 선택지를 렌더할 때 기타선택지가 선택되었는지 판별하고 선택되었으면 기타 내용과 함께 렌더 */}
            {answersIndex + 1 == questionItem.length && answer.includes(`${questionItem.indexOf("기타:") + 1}`) ? answersItem + etc : answersItem}
          </CheckBoxLabel>
        </CheckItem>
      ))}
    </CheckAnswerList>
  )
}
