import React from "react"
import styled from "styled-components"
import { ResponseItemType } from "../container.individual"
import { CheckAnswer, RadioAnswer, ShortText, CheckRankingAnswer, RadioImageAnswer, SmallHeader } from "./answerModules"

const AnswerItemBox = styled.div`
  position: relative;
  background-color: #fbfaf8;
  margin: 1rem 3.571rem;
  padding: 2rem;
  border-radius: 5px;
  page-break-inside: avoid;
  page-break-after: auto;
  border: 1px solid #e9e1d5;
`
const AnswerInnerBox = styled.div``
const QuestionSectionBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const AnswerSectionBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
`
const BasicText = styled.p``
const AccentText = styled.b`
  font-family: "Moto Heavy Demo";
  color: #fdc356;
  font-size: 1.8rem;
`
const IsRequiredSpan = styled.span`
  position: absolute;
  top: 0.6rem;
  left: 0.7rem;
  color: #59c4db;
  font-size: 2rem;
`

type AnswerItemType = {
  item: ResponseItemType
}

function AnswerItem({ item }: AnswerItemType) {
  const switchModule = (type: string) => {
    switch (type) {
      case "radio":
        return <RadioAnswer data={{ questionItem: item.selections, questionIndex: item.questionIndex }} answer={item.answer} />
      case "check":
        return <CheckAnswer data={{ questionItem: item.selections, questionIndex: item.questionIndex }} answer={item.answer} etc={"asdf"} />
      case "check_ranking":
        return <CheckRankingAnswer data={{ orderData: item.answer.split(",").map((item) => item.trim()) }} />
      case "radio_image_selections":
        return <RadioImageAnswer data={{ questionItem: { body: item.selections, image_selections: item.image_selections! } }} answer={item.answer} />
      case "shorttext":
        return <ShortText answer={item.answer} subType={"text"} />
      case "smallheader":
        return <SmallHeader />
      default:
        break
    }
  }

  return (
    <AnswerItemBox>
      {item.isRequired && <IsRequiredSpan>*</IsRequiredSpan>}
      <AnswerInnerBox>
        <QuestionSectionBox>
          <AccentText>Q{item.questionIndex + 1}.</AccentText>
          <BasicText>{item.questionTitle}</BasicText>
        </QuestionSectionBox>
        <AnswerSectionBox>
          <AccentText>A.</AccentText>
          {switchModule(item.questionType)}
        </AnswerSectionBox>
      </AnswerInnerBox>
    </AnswerItemBox>
  )
}

export default AnswerItem
