import React from "react"
import styled from "styled-components"
import AnswerItem from "./AnswerItem"
import { ResponseItemType } from "../container.individual"

const AnswerListBox = styled.div`
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e9e1d5;
  width: 100%;
  margin-top: 1rem;
`
const ControlBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 3.571rem;
  z-index: 10;
  background-color: white;
  margin-left: 0.1rem;
  margin-top: 0.1rem;
`
const RequiredText = styled.span`
  color: #59c4db;
  font-size: 1.3rem;
  font-family: "Noto Sans CJK KR Regular";
`

export type AnswerListType = {
  responseData: ResponseItemType[]
}

export function AnswerList({ responseData }: AnswerListType) {
  return (
    <AnswerListBox>
      <ControlBox>
        <RequiredText>* 필수응답</RequiredText>
      </ControlBox>
      {responseData.map((item, index) => (
        <AnswerItem key={index} item={item} />
      ))}
    </AnswerListBox>
  )
}
