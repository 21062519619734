import React from "react"

export type ShortTextType = {
  answer: string
  subType: string
}

export const ShortText = ({ answer, subType }: ShortTextType) => {
  if (/https?:\/\/(www.)?youtu(be)?\.(be|com)\/(watch\?v=)?[a-zA-Z0-9]{11}/.test(answer)) {
    return (
      <div className="shorttext-answer-container">
        <iframe width="350px" height="200px" src={`https://www.youtube.com/embed/${answer.substr(answer.length - 11, answer.length)}`}></iframe>
      </div>
    )
  } else if (subType === "img" || answer.endsWith(".jpeg") || answer.endsWith(".jpg") || answer.endsWith(".gif") || answer.endsWith(".png") || answer.endsWith(".bmp")) {
    return (
      <div className="shorttext-answer-container">
        <img className="image-answer" src={answer} alt="이미지 응답" />
      </div>
    )
  } else if (subType === "video" || answer.endsWith(".mp4") || answer.endsWith(".m4a") || answer.endsWith(".avi") || answer.endsWith(".mov")) {
    return (
      <div className="shorttext-answer-container">
        <video width="400" height="auto" controls src={answer}>
          <source type="video/mp4" />
        </video>
      </div>
    )
  } else {
    return (
      <div className="shorttext-answer-container">
        <div className="text-answer" style={{ marginLeft: "10px" }}>
          {answer}
        </div>
      </div>
    )
  }
}
