import React from "react"
import styled from "styled-components"
import axios from "axios"
import IndividualContainer, { ResponseItemType } from "../../container/individual/container.individual"
import { RouteComponentProps } from "react-router"
import { ReactComponent as TimeSvg } from "./time-left.svg"

const NoDataContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`
const InnerBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const YellowBox = styled.div`
  position: relative;
  width: 310px;
  height: 310px;
  background-color: #fac62d;
`
const TimeIcon = styled(TimeSvg)`
  position: absolute;
  width: 180px;
  bottom: 0;
  right: 0;
  fill: white;
`
const WaitingH1 = styled.h1`
  font-size: 47px;
  color: #fac62d;
  font-weight: 400;
  margin: 12px;
`
const DescriptionP = styled.p`
  text-align: center;
  margin: 0;
  letter-spacing: 1.5px;
`

function Individual({ match }: RouteComponentProps<{ uid: string }>) {
  const {
    params: { uid },
  } = match

  const [data, setData] = React.useState<{
    surveyId: string
    surveyTitle: string
    surveyDescription: string
    responseData: ResponseItemType[]
  } | null>(null)

  React.useEffect(() => {
    async function getIndividualResponse() {
      const response = await axios.get(`https://j05fstwuug.execute-api.ap-northeast-2.amazonaws.com/prod?UID=${uid}`)

      let tempResponseData: ResponseItemType[] = []

      response.data.bp_data.questions.map(
        (
          item: {
            answers: string[]
            question_order: string
            question_title: string
            type: string
            image_selections: string[] | undefined
          },
          index: number
        ) => {
          let itemType = item.type

          if (item.type == "radio_image_selections") itemType = "radio"
          else if (item.type == "check_ranking") itemType = "check"

          let answerKey = `[${index + 1}] ${item.question_title.replace(/\n/g, " ")}(${itemType})`

          tempResponseData.push({
            questionTitle: item.question_title,
            selections: item.answers,
            questionIndex: index,
            questionType: item.type,
            answer: response.data[answerKey],
            image_selections: item.image_selections ? item.image_selections : null,
            isRequired: true,
          })
        }
      )

      setData({
        surveyId: response.data.survey_id,
        surveyTitle: response.data.bp_data.survey_title,
        surveyDescription: response.data.bp_data.survey_description,
        responseData: tempResponseData,
      })
    }

    getIndividualResponse()
  }, [])

  if (data) {
    return (
      <div style={{ backgroundColor: "#f6f3ee", padding: "60px 0" }}>
        <IndividualContainer surveyId={data!.surveyId} surveyTitle={data!.surveyTitle} surveyDescription={data!.surveyDescription} responseData={data!.responseData} />
      </div>
    )
  } else {
    return (
      <NoDataContainer>
        <InnerBox>
          <YellowBox>
            <TimeIcon />
          </YellowBox>
          <WaitingH1>조금만 기다려주세요!</WaitingH1>
          <DescriptionP>
            열심히 보고서를 준비하고 있습니다. 잠시 후에 다시 방문해주세요.
            <br />
            만약 8시간이 지나도 계속 준비중이라면, 정상적인 주소인지 확인해보세요.
          </DescriptionP>
        </InnerBox>
      </NoDataContainer>
    )
  }
}

export default Individual
