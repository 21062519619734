import React from "react"
import styled from "styled-components"

const ImageSelectionItemBox = styled.div`
  position: relative;
  margin: 0 0.9rem 1rem 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 3px #00000029;
  border-radius: 0.8rem;
  float: left;
`
const ImgBox = styled.div`
  width: 100%;
  height: 55%;
  border-radius: 0.8rem;
  position: relative;
  text-align: center;
`
const ImgMask = styled.div`
  width: 100%;
  height: 100%;
  z-index: 3;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  font-family: "Noto Sans CJK KR Regular";
  border-bottom: 0.5px solid #e2e2e2;
`
const ImgItem = styled.img`
  max-width: 100%;
  max-height: 100%;
`
const ContentBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
`
const DescriptionBox = styled.div`
  width: 100%;
  height: 45%;
  border-radius: 0 0 0.8rem 0.8rem;
`
const DescriptionP = styled.p`
  margin-left: 1rem;
  margin-top: 1rem;
`

export type RadioImageAnswerType = {
  data: {
    questionItem: {
      body: string[]
      image_selections: string[]
    }
  }
  answer: string
}

export const RadioImageAnswer = ({
  data: {
    questionItem: { body, image_selections },
  },
  answer,
}: RadioImageAnswerType) => {
  const selectionData: any = []

  body.map((item, index) => {
    if (selectionData[index] === undefined) {
      selectionData[index] = { id: index, description: item, imageUrl: "" }
    } else {
      selectionData[index] = {
        id: index,
        description: item,
        imageUrl: image_selections[index],
      }
    }
  })

  if (image_selections !== undefined) {
    image_selections.map((item, index) => {
      selectionData[index].imageUrl = item
    })
  }

  return (
    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      {selectionData.map((item: any, index: number) => (
        <Item itemData={item} key={index} answer={answer} />
      ))}
    </div>
  )
}

const Item = ({ itemData, answer }: { itemData: any; answer: any }) => {
  return (
    <ImageSelectionItemBox
      data-testid="image-selections-answer"
      style={{
        zIndex: 4,
        border: itemData.description === answer ? "2px solid #f2ab27" : "",
        width: "11.85rem",
        height: "10.92rem",
      }}
    >
      <div className="buttonBox">
        <div style={{ flex: 1 }} />
      </div>
      <ContentBox>
        <ImgBox>
          <ImgMask>
            <ImgItem src={itemData.imageUrl} />
          </ImgMask>
        </ImgBox>
        <DescriptionBox>
          <DescriptionP>{itemData.description}</DescriptionP>
        </DescriptionBox>
      </ContentBox>
    </ImageSelectionItemBox>
  )
}
